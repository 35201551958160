<template>
  <div>
    <div id="error" class="row align-items-center justify-content-center">
      <div class="col-12 text-center">
        <img class="mb-3" src="/img/errors/401.svg" alt="404" height="400px" />
        <div class="body">
          <h1 class="font-weight-bold dark-blue-text">
            このページにアクセスできません
          </h1>
          <h5 class="grey-text">
            申し訳ありませんが、現在このページにアクセスする権限がありません。
            管理者またはこのページを共有した人に連絡してください
            アクセスを許可します。
          </h5>
          <router-link
            class="btn btn-ds d-block my-2 mt-3 mb-0"
            :to="{ name: 'anonymousRoute-home' }"
          >
            <font-awesome-icon
              class="mr-3 text-light-gray fa-sm"
              :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
            />
            <span>家に帰る</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: '401 | Unauthorized',
    };
  },
};
</script>

<style scoped>
#error {
  margin: auto;
  height: 80vh;
  width: 900px;
}

@media only screen and (max-width: 1024px) {
  #error {
    width: 100%;
  }
}

#error .body {
  position: absolute;
  z-index: 100;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  #error .body {
    position: relative;
    z-index: 100;
    width: 80%;
    margin: auto auto -200px auto;
  }
}
</style>
